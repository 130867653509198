import React from "react";
import { FaPhone } from "react-icons/fa";

const CallButton = ({ mobile }) => {
  return (
    <span
      className="contact-button contact-mode"
      style={{ backgroundColor: "rgb(78 190 255)" }}
    >
      <a href={`tel:${mobile}`}>
        <FaPhone style={{ width: 20, height: 20 }} color="white" />
      </a>
    </span>
  );
};

export default CallButton;
