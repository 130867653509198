import { API } from "../../constants/api.constants";

const { GET_DEALERS } = API;

export const getDealersApi = async ({ skip, limit }) => {
  return await fetch(`${GET_DEALERS}?skip=${skip}&limit=${limit}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((response) => {
      if (response.status)
        return {
          status: true,
          data: response.data,
        };
      else {
        return { status: false, message: response.message };
      }
    })
    .catch(function (error) {
      return {
        status: false,
        message: "Something went wrong, please try again later.",
        error,
      };
    });
};
