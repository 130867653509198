import React, { useState } from "react";
import "./styles.css"; // Import the styles
import { FaBoxes, FaHome, FaUserCog } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const TabLayout = ({ defaultTab }) => {
  const [activeTab, setActiveTab] = useState(defaultTab || "home");
  const navigate = useNavigate();

  const tabs = [
    { id: "home", label: "Home", url: "/", icon: <FaHome size={23} /> },
    // {
    //   id: "category",
    //   label: "Categories",
    //   url: "/category",
    //   icon: <FaBoxes size={23} />,
    // },
    {
      id: "dealers",
      label: "Dealers",
      url: "/dealers",
      icon: <FaUserCog size={23} />,
    },
  ];

  const onChangeTab = (tab) => {
    setActiveTab(tab.id);
    navigate(tab.url);
  };

  return (
    <div className="tab-layout">
      {/* <div className="tab-content">
        {activeTab === "home" && <div>Welcome to the Home Tab</div>}
        {activeTab === "search" && <div>Search for something...</div>}
        {activeTab === "profile" && <div>This is your Profile</div>}
      </div> */}
      <div className="tab-buttons">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`tab-button ${activeTab === tab.id ? "active" : ""}`}
            onClick={() => onChangeTab(tab)}
          >
            <div>{tab.icon}</div> <div className="tab-label">{tab.label}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabLayout;
