import React, { useEffect, useState } from "react";
import HomeTemplate from "./index.template";
import { getHomeDataAPI } from "./home.service";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { groupBy } from "../../utils/common";

const HomeComponent = () => {
  const [all, setAll] = useState({
    loading: true,
    categories: [],
    posts: [],
    search: "",
    searchResults: [],
    groupBuys: [],
  });
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const queryParams = Object.fromEntries(params.entries());

  const onLoad = async () => {
    const resp = await getHomeDataAPI();

    if (resp.status) {
      setAll({
        ...all,
        search: queryParams?.key || "",
        loading: false,
        categories: resp.data.categorys,
        posts: resp.data.all_post,
        groupBuys: resp.data.group_buys,
      });
    }
  };

  const handleSearch = (value) => {
    const results = all.posts.filter((post) =>
      post.title.toLowerCase().includes(value.toLowerCase())
    );

    const phoneResults = all.posts.filter((post) =>
      post?.customer_details?.phone
        ? post.customer_details.phone
            .toLowerCase()
            .includes(value.toLowerCase())
        : false
    );

    const customerResults = all.posts.filter((post) =>
      post?.customer_details?.name
        ? post.customer_details.name.toLowerCase().includes(value.toLowerCase())
        : false
    );

    const locationResults = all.posts.filter((post) =>
      post?.customer_details?.location
        ? post.customer_details.location
            .toLowerCase()
            .includes(value.toLowerCase())
        : false
    );

    const combinedResults = [
      ...results,
      ...phoneResults,
      ...customerResults,
      ...locationResults,
    ];

    const groupedResults = groupBy(combinedResults, "id");

    setAll({
      ...all,
      loading: false,
      search: value,
      searchResults: groupedResults,
    });
  };

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    if (all.posts && queryParams?.key) handleSearch(queryParams.key);
  }, [all.posts, queryParams.key]);

  return (
    <HomeTemplate {...all} handleSearch={handleSearch} navigate={navigate} />
  );
};

export default HomeComponent;
