import React from "react";
import { FaWhatsapp } from "react-icons/fa";

const WhatsappButton = ({ mobile, message = "Hi" }) => {
  return (
    <span
      className="contact-button contact-mode"
      style={{
        //   marginLeft: "30px",
        backgroundColor: "#65ce40 ",
      }}
    >
      <a
        href={`https://wa.me/+91${mobile.replace(
          /\D/g,
          ""
        )}?text=${encodeURIComponent(message)}`}
      >
        <FaWhatsapp style={{ width: 20, height: 20 }} color="white" />
      </a>
    </span>
  );
};

export default WhatsappButton;
