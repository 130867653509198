import React from "react";
import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AppHeaderComponent from "../../components/app-header/app-header.component";
import AppCategoriesComponent from "../../components/app-categories/app-categories.component";
import AppProductsComponent from "../../components/app-products/app-products.component";

import LoaderComponent from "../../components/loader/loader";
import { isAdmin } from "../../utils/common";
import GroupBuyCard from "../../components/group-buy-card/group-buy-card";
import TabLayout from "../../components/tab-layout/tab-layout.component";

const HomeTemplate = ({
  loading,
  categories,
  posts,
  groupBuys,
  search,
  searchResults = [],
  handleSearch,
  navigate,
}) => {
  return (
    <PageWrapperComponent>
      <div className="app-container bg">
        <AppHeaderComponent search={search} handleSearch={handleSearch} />

        {loading && (
          <div className="t-a-c" style={{ paddingTop: 125 }}>
            <LoaderComponent
              path={require("./../../assets/icons/loading-gif.gif")}
            />
          </div>
        )}

        {!loading && (
          <div style={{ paddingBottom: isAdmin() ? 100 : 0 }}>
            {search && (
              <div style={{ paddingTop: 105 }}>
                {" "}
                <AppProductsComponent
                  name="Search Results"
                  posts={searchResults}
                />{" "}
              </div>
            )}

            {!search && (
              <>
                <div>
                  <AppCategoriesComponent
                    search={search}
                    categories={categories}
                  />
                </div>

                {groupBuys.length > 0 && (
                  <GroupBuyCard data={groupBuys} navigate={navigate} />
                )}

                <div style={{ marginTop: 20 }}>
                  <AppProductsComponent
                    name={`All Posts (${posts.length})`}
                    posts={posts}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>

      <TabLayout />
    </PageWrapperComponent>
  );
};

export default HomeTemplate;
