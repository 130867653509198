import React, { useEffect, useState } from "react";
import DealersTemplate from "./dealers.template";
import { getDealersApi } from "./dealers.service";

const DealersScreen = () => {
  const [all, setAll] = useState({ loading: true, dealers: [] });
  const onLoad = async () => {
    setAll({ ...all, loading: true });
    const res = await getDealersApi({ skip: 0, limit: 100 });
    console.log({ res });
    if (res.status) {
      setAll({ ...all, loading: false, dealers: res.data });
    } else setAll({ ...all, loading: false });
  };

  useEffect(() => {
    onLoad();
  }, []);
  return <DealersTemplate {...all} />;
};

export default DealersScreen;
