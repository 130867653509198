import React from "react";
import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../components/account-header/account-header.component";
import TabLayout from "../../components/tab-layout/tab-layout.component";
import LoaderComponent from "../../components/loader/loader";
import EmptyBoxComponent from "../../components/empty-box/empty-box";
import CallButton from "../../components/post-share/call-button";
import { FaAddressBook, FaMobile, FaUserCog } from "react-icons/fa";
import WhatsappButton from "../../components/post-share/wa-button";

const DealersTemplate = ({ loading, dealers }) => {
  return (
    <PageWrapperComponent>
      <AccountHeaderComponent name="Dealers" backRoute="/" />
      <div className="page-content">
        {loading && (
          <div className="t-a-c" style={{ paddingTop: 125 }}>
            <LoaderComponent
              path={require("./../../assets/icons/loading-gif.gif")}
            />
          </div>
        )}

        {!loading && dealers.length === 0 && (
          <div className="container t-a-c">
            <EmptyBoxComponent message="No dealers found" />
          </div>
        )}

        {dealers.map((dealer) => (
          <div className="dealer-card">
            <div className="dealer-name primary-color flex">
              <div>
                <FaUserCog style={{ marginTop: 5 }} size={20} />
              </div>
              <div className="dealer-info">{dealer.name}</div>
            </div>
            <div className="dealer-address primary-color flex">
              <div>
                {" "}
                <FaAddressBook size={18} />{" "}
              </div>
              <div className="dealer-address-label">{dealer.adress} </div>
            </div>
            <div className="dealer-mobile primary-color flex">
              <div>
                <FaMobile size={20} />{" "}
              </div>{" "}
              <div className="dealer-address-label">{dealer.mobile}</div>
            </div>

            <div className="t-a-c">
              {/* <div> Connect with dealer </div> */}

              <div>
                <CallButton mobile={dealer.mobile} />
                <WhatsappButton
                  mobile={dealer.mobile}
                  message="Hi, I got your contact information from VagBay."
                />
              </div>
            </div>
          </div>
        ))}

        <TabLayout defaultTab={"dealers"} />
      </div>
    </PageWrapperComponent>
  );
};

export default DealersTemplate;
